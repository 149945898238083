<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <v-card flat>
          <v-card-title>
            404
          </v-card-title>
          <v-card-subtitle>
            The page you are looking for is missing or does not exist.
          </v-card-subtitle>
          <v-card-actions>
            <v-btn
              to="/"
              color="primary"
            >
              Home
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'not-found'
}
</script>
